import "./App.css";

import { useLocation, Routes, Route } from "react-router-dom";
import Home from "./Home";

function App() {
  let location = useLocation();
  window.location.href = "https://app.sortful.de/" + location.pathname;
  return (
    <>
      <Routes>
        <Route path="/assets" element={<Home />} />
        <Route path="/assets/:id" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
