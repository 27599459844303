import "./App.css";

import { useLocation } from "react-router-dom";

function Home() {
  let location = useLocation();
  window.location.href = "https://app.sortful.de/" + location.pathname;

  return <></>;
}

export default Home;
